import React from "react";
import { Helmet } from "react-helmet";
import Parallax from "../components/Parallax";

import "../styles/materialize.css";
import "../styles/styles.css";

import Layout from "../components/Layout";

export default function Home() {
  return (
    <>
      <Layout>
        <div className="container">
          <p>
            <br />
            <h1 className="center">La página a la que está intentando acceder no existe</h1>
            <br />
          </p>
        </div>
      </Layout>
    </>
  );
}
